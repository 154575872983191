<template>
  <base-section id="404">
    <base-heading title="Error 404" />

    <base-subheading
      class="primary--text"
      :title="this.$i18n.t('sections.404.title')"
    />

    <base-subheading
      space="8"
      :title="this.$i18n.t('sections.404.subtitle')"
    />

    <div class="text-center">
      <base-btn to="/">
        {{this.$i18n.t('sections.404.getMeOut')}}
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
